<template>
    <v-container fluid :class="$attrs.class" style="height: 90%"> 
        <v-data-table :items="filteredData" :headers="headers" :sort-by="sortBy" :sort-desc="sortDesc" :search="search" :custom-filter="customFilter" class="fixed-header-table" fixed-header  height="500px" :items-per-page="-1" >
            <!-- <template v-slot:[`item.timestamp`]="{ item }">
                {{ item.timestamp | formatDate }}
            </template> -->
            <template v-slot:[`item.valor`]="{ item }">
            <span v-if="item.assinatura">
                {{ item.valor | formatCurrency }}
            </span>
            </template>
            <template v-slot:[`item.assinatura`]="{ item }">
                <span :class="item.assinatura ? 'text-green' : 'text-red'">
                {{ item.assinatura ? 'SIM' : 'NÃO' }}
                </span>
            </template>
            <template v-slot:[`item.timestamp`]="{ item }">
                {{ formatDate(item.timestamp) }}
            </template>
            <!-- Campo de pesquisa  -->
           <template v-slot:top>
                <v-row>
                    <v-col cols="5">
                        <v-text-field label="Pesquise aqui..." dense outlined  v-model="search" class="mx-0" append-icon="mdi-magnify" clearable />
                    </v-col>
                </v-row>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>

  export default {
    name:'Faturamento',
    props: {
      tableData: {type: Array, default: () => []}
    },
    data: () => ({
        prestador: { apelido: null },
        sortBy: ['colaborador', 'timestamp'],
        sortDesc: [false, false],
        search: '',
        headers:[
            {text:'ID do profissional', value:'colaborador_id'},
            {text:'Nome do profissional', value:'colaborador'},
            {text:'Especialidade', value:'especialidade_nome'},
            {text:'ID do paciente', value:'paciente_id'},
            {text:'Nome do paciente', value:'paciente'},
            {text:'Nº do orçamento', value:'sessao_id'},
            {text:'ID da evolução', value:'id'},
            {text:'Data da evolução', value:'timestamp'},
            {text:'Evolução válida?', value:'assinatura'},
            {text:'Valor da evolução', value:'valor'},
        ]
    }),
    methods: {
        customFilter(value, search, item) {
            // Função para remover acentos e deixar em minúsculas para comparação
            const normalize = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

            // Aplica a normalização tanto para a pesquisa quanto para o valor
            const normalizedSearch = normalize(search);
            const normalizedValue = normalize(String(value));
            const normalizedWeekday = normalize(this.formatDate(item.timestamp).split(' - ')[1]);

            // Retorna true se o valor normalizado incluir o termo de pesquisa normalizado
            return normalizedValue.includes(normalizedSearch) || normalizedWeekday.includes(normalizedSearch);
        },
        formatDate(value) {
            if (value) {
                const date = new Date(value);
                const day = ("0" + date.getDate()).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                const weekDay = date.toLocaleDateString('pt-BR', { weekday: 'long' });
                return `${day}/${month}/${year} - ${weekDay.charAt(0).toUpperCase() + weekDay.slice(1)}`;
            }
            return "";
        },
    },
    computed: { // essa propriedade computada serve para retira da renderização a linha correspondente ao remumo
        filteredData() {
            return this.tableData.filter(item => item.timestamp);
        }
    },
    filters: {
        formatCurrency(value) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
        },
    },
  }
</script>

<style scoped>
.text-green { color: green; }
.text-red { color: red; }

.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
  